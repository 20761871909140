#pf-flow-cell .preview {
        display: flex;
        justify-content: center;
        align-items: center;
}

#pf-flow-cell .w-row {
        display: flex;
        margin: 0 auto 3rem;
}

@media screen and (max-width: 991px) {
        #pf-flow-cell {
                margin: 0;
        }

        #pf-flow-cell .designer-container {
                padding-left: 0;
                padding-right: 0;
        }
}

@media screen and (max-width: 1260px) {
        #pf-flow-cell .designer-container {
                max-width: 960px;
        }
}

@media screen and (min-width: 1260px) {
        #pf-flow-cell .designer-container {
                padding-left: 160px;
                padding-right: 160px;
        }
}

#pf-flow-cell .button-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
}

#pf-flow-cell .button-container button {
        margin: 0 1rem;
}

@media screen and (max-width: 1200px) {
        #pf-flow-cell .button-container {
                flex-direction: column;
                align-items: normal;
        }
        
        #pf-flow-cell .button-container button {
                margin: 1rem 0 0 0;
        }
}


#pf-flow-cell .designer-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        margin: 0px 20px;
}

#pf-flow-cell .designer-container .form-controls {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        align-items: stretch;
        width: 30%;
}

#pf-flow-cell .designer-container .preview {
        width: 70%;
        display: flex;
}

@media screen and (max-width: 479px) {
        #pf-flow-cell .designer-container {
                flex-direction: column;
                margin: 0;
        }

        #pf-flow-cell .designer-container .form-controls {
                width: 100%;
        }

        #pf-flow-cell .designer-container .preview {
                width: 100%;
                height: 50vh;
        }
}